<template>
  <StatementHeader is-serviceStatement :ifDisabled="loading" />

  <div class="centered-loading-wrapper" v-if="loading">
    <LoadingSpinner />
  </div>

  <Card
    v-else
    class="statement__body"
    :title="selectedDate"
    flexDirection="column"
    :contentGap="0">
    <template v-slot:upper-right>
      <StatementStatus v-if="!isSealed" />
      <IconButton
        v-else
        :path="mdiDownload"
        text="Download Statement"
        is-textStyle
        is-black />
    </template>

    <template v-slot:content>
      <template v-if="!ifIncludeOrders">
        <div v-if="!rows">No Data</div>
        <StatementRow
          v-else
          v-for="(value, key) in rows"
          :key="key"
          :title="key"
          :value="this.formatMoney(value)" />
      </template>

      <StatementOrdersTable :serviceId="serviceId" v-else />
    </template>
  </Card>
</template>

<script>
  import store from "@/store";
  import StatementHeader from "@/components//Statements/StatementHeader.vue";
  import Card from "@/components/Card.vue";
  import IconButton from "@/components/Buttons/IconButton.vue";
  import StatementStatus from "@/components/Statements/StatementStatus.vue";
  import StatementRow from "@/components/Statements/Services/StatementRow.vue";
  import StatementOrdersTable from "@/components/Statements/Services/StatementOrdersTable.vue";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import { mdiDownload } from "@mdi/js";
  import { useToast } from "vue-toastification";
  import { LocalDate, DateTimeFormatter } from "@js-joda/core";

  export default {
    data() {
      return {
        mdiDownload,
        loading: false,
        rows: null,
        ifIncludeOrders: this.$route.query.orders || false,
        selectedDate:
          this.$route.query.date ||
          LocalDate.now().format(DateTimeFormatter.ofPattern("yyyy-MM-dd")),
        isSealed: false,
        serviceId: null,
      };
    },
    methods: {
      async fetchStatement(serviceId, ifIncludeOrders = this.ifIncludeOrders) {
        this.ifIncludeOrders = ifIncludeOrders;

        try {
          const response =
            await store.state.apiPrivate.client.endpoints.statementsServices.get(
              serviceId
            );
          const data = await response?.data?.data;
          this.rows = data;
        } catch (error) {
          useToast().error(error);
        }
        this.loading = false;
      },

      async getStatementId() {
        this.loading = true;

        // To be improved: if there is another way to get serviceId easier
        try {
          const response =
            await store.state.apiPrivate.client.endpoints.events.getWithQuery({
              outlets: [this.$route.params.operatorId],
              dateFrom: this.selectedDate,
              dateTo: this.selectedDate,
              includeServices: true,
            });

          const service = await response.data?.data?.services[0];
          const { id, status } = service;
          this.serviceId = id;
          this.isSealed = status;

          this.fetchStatement(id);
        } catch (error) {
          useToast().error(error);
        }
      },
    },

    mounted() {
      this.getStatementId();
    },

    components: {
      Card,
      IconButton,
      StatementStatus,
      StatementRow,
      StatementOrdersTable,
      LoadingSpinner,
      StatementHeader,
    },
  };
</script>
