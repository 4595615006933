<template>
  <div
    class="statement-row"
    @click="toggleRowExpand"
    :class="{ expandable: ifExpandable }"
  >
    <h2>
      <span class="title">{{ this.upperCamelCaseToString(title) }}</span>
      <span class="value">{{ value }}</span>
    </h2>

    <template v-if="ifExpandable">
      <Icon
        :path="mdiChevronUp"
        v-if="ifExpand"
      />
      <Icon
        :path="mdiChevronDown"
        v-else
      />
    </template>

  </div>

  <!--For Data which needs a expand view for details-->
  <!-- <AgGridVue
    v-if="ifExpand"
    style="width: 100%; height: 160px"
    class="ag-theme-alpine data-table__content"
    @gridReady="onGridReady"
    :columnDefs="tableCols"
    :rowData="tableData"
  >
  </AgGridVue> -->
</template>

<script>
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
// import { AgGridVue } from 'ag-grid-vue3';
// import { useToast } from 'vue-toastification';
import { upperCamelCaseToString } from '@/helpers/stringFormatter';

export default {
  data() {
    return {
      ifExpandable: false,
      ifExpand: false,
      gridApi: null,
      columnApi: null,
      tableCols: [],
      tableData: [],
      mdiChevronUp,
      mdiChevronDown
    }
  },
  props: {
    title: {
      type: String
    },
    value: {
      type: String,
      default: '-'
    },
  },

  methods: {
    upperCamelCaseToString,

    toggleRowExpand() {
      if (!this.ifExpandable) return;

      // this.ifExpand = !this.ifExpand;

      // if (this.ifExpand) {


      //   try {
      //     // API Call: Fetch Row Detail 

      //     // Fake Data
      //     this.tableCols = [{
      //       headerName: "Type",
      //       field: "type",
      //       width: 200,
      //     },
      //     {
      //       headerName: "Total",
      //       field: "total",
      //     },
      //     {
      //       headerName: "Cash",
      //       field: "cash",
      //     }, {
      //       headerName: "Card",
      //       field: "card",
      //     },
      //     {
      //       headerName: "Card Manual",
      //       field: "cardManual",
      //     },
      //     {
      //       headerName: "Promo",
      //       field: "promo",
      //       flex: 1
      //     }]

      //     this.tableData = [
      //       {
      //         type: "Walk Up",
      //         total: this.formatMoney(0),
      //         cash: this.formatMoney(0),
      //         card: this.formatMoney(0),
      //         cardManual: this.formatMoney(0),
      //         promo: this.formatMoney(0),
      //       },
      //       {
      //         type: "Online",
      //         total: this.formatMoney(0),
      //         cash: this.formatMoney(0),
      //         card: this.formatMoney(0),
      //         cardManual: this.formatMoney(0),
      //         promo: this.formatMoney(0),
      //       },
      //     ]
      //   } catch (error) {
      //     useToast().error(error)
      //   }

      // }
    },

    // onGridReady(params) {
    //   this.gridApi = params.api;
    //   this.gridColumnApi = params.columnApi;
    // },
  },

  // components: { AgGridVue },

  // mounted() {
  //   // Temp for layout, need to be changed
  //   if (this.title === 'grossRevenueTotal') {
  //     this.ifExpandable = true;
  //   }
  // }
}
</script>

<style lang="scss">
.statement-row {
  @include flex-center($jc: space-between);
  width: 100%;
  border-bottom: $table_border;

  &.expandable {
    cursor: pointer;
  }

  h2 {
    font-size: 1rem;
    font-weight: bold;
    width: 80%;
    min-width: 250px;
    max-width: 500px;
    text-transform: capitalize;
    @include flex-center($jc: space-between);

    >span {
      display: inline-block;
    }

    &.title {
      width: 200px;
    }

    &.value {
      text-align: right;
    }
  }
}
</style>