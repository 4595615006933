<template>
  <div class="main-content statement">
    <h3 class="page-heading">Service Statement</h3>

    <!-- <StatementHeader is-serviceStatement /> -->
    <StatementDetails />


  </div>
</template>

<script>
// import StatementHeader from '@/components//Statements/StatementHeader.vue';
import StatementDetails from "@/components/Statements/Services/StatementDetails.vue";

export default {
  components: {
    StatementDetails,
    // StatementHeader
  },

};
</script>

<style lang="scss">
.statement {
  &__body {
    .card__title {
      justify-content: flex-start;

      >.slot {
        flex: 1;
        @include flex-center($jc: flex-start);
      }
    }

    .card__content {
      height: calc(100% - 4rem);
    }
  }
}
</style>